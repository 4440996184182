import React from 'react';
import { format, isValid, parse } from 'date-fns';
import { FormInput, CustomField, FormTextArea } from 'components/next/components/form/input';
import LanguageSelector from 'components/common/next/languageSelector';
import { Checkbox } from 'components/next/components/checkbox';
import type { ContentField, DeliveryLanguage } from 'components/next/types';
import * as _ from 'lodash';
import './fieldEditor.scss';
import Dropdown, { DropdownItem } from '../dropdown';
import InputImage from 'components/common/next/form/inputImage';
import { ReactComponent as IconUp } from '@kesko/icons/action/icon-push_up.svg';
import { ReactComponent as IconDown } from '@kesko/icons/action/icon-push_down.svg';
import { ReactComponent as IconDelete } from '@kesko/icons/action/icon-delete.svg';
import BttProductSelect from 'components/common/next/offerEditor/bttProductSelect';
import InputNumber from 'components/common/next/form/inputNumber';
import { ContentFieldType } from 'enums/common';

interface FieldEditorProps {
  index: number;
  preventOverWrites?: boolean;
  contentField: ContentField;
  update?(c: ContentField): void;
  deleteField(id: string): void;
  changeOrder(o: number, s: boolean): void;
}

interface FieldEditorState {
  language: DeliveryLanguage;
}

export default class FieldEditor extends React.Component<FieldEditorProps, FieldEditorState> {
  constructor(props: FieldEditorProps) {
    super(props);
    this.state = { language: 'fi' };
    this.onTypeChange = this.onTypeChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.renderPropEditor = this.renderPropEditor.bind(this);
    this.getPropValue = this.getPropValue.bind(this);
  }

  handleUpdate(fieldName: string, value: any) {
    const { update } = this.props;
    const contentField = { ...this.props.contentField };
    update(_.set(contentField, fieldName, value));
  }

  onTypeChange(type: string) {
    this.handleUpdate('type', type);
  }

  getPropValue(propName) {
    const { contentField } = this.props;
    if (!contentField || !contentField.props) {
      return '';
    }
    return contentField.props[propName] || '';
  }

  renderPropEditor() {
    const propFields = this.getPropFields();
    if (!propFields) {
      return null;
    }
    return (
      <div className="field-editor__props">
        <header>
          <h3>Content field additional properties</h3>
        </header>
        {propFields}
      </div>
    );
  }

  getPropFields() {
    const { contentField } = this.props;
    const { language } = this.state;
    const minLength = (
      <FormInput
        key="minLength"
        type="number"
        label="Min length"
        value={this.getPropValue('minLength')}
        additionalClasses="half-wide"
        handleChange={(e) => this.handleUpdate('props.minLength', e.target.value)}
      />
    );
    const maxLength = (
      <FormInput
        key="maxLength"
        type="number"
        label="Max length"
        value={this.getPropValue('maxLength')}
        additionalClasses="half-wide"
        handleChange={(e) => this.handleUpdate('props.maxLength', e.target.value)}
      />
    );
    const pattern = (
      <FormInput
        key="pattern"
        label="Pattern"
        value={this.getPropValue('pattern')}
        handleChange={(e) => this.handleUpdate('props.pattern', e.target.value)}
      />
    );
    const placeholder = (
      <FormInput
        key="placeholder"
        label="Placeholder"
        value={this.getPropValue('placeholder')}
        handleChange={(e) => this.handleUpdate('props.placeholder', e.target.value)}
      />
    );
    const allowedTags = (
      <FormInput
        key="allowedTags"
        label="Allowed tags"
        value={this.getPropValue('allowedTags')}
        handleChange={(e) => this.handleUpdate('props.minLength', e.target.value)}
        detail="Insert allowed tags separated by a comma"
      />
    );
    const minDate = (
      <FormInput
        key="minDate"
        type="date"
        label="Min"
        value={this.getPropValue('maxDate')}
        handleChange={(e) => this.handleUpdate('maxDate', e.target.value)}
      />
    );
    const maxDate = (
      <FormInput
        key="maxDate"
        type="date"
        label="Max"
        value={this.getPropValue('maxDate')}
        handleChange={(e) => this.handleUpdate('maxDate', e.target.value)}
      />
    );
    const options = (
      <FormInput
        key="options"
        label="Options"
        value={this.getPropValue('options')}
        handleChange={(e) => this.handleUpdate('props.options', e.target.value)}
        detail="Insert possible options separated by a comma"
      />
    );

    const defaultValue = this.getContent('defaultValue');
    const isDefaultValueObject = typeof defaultValue === 'object';
    const defaultImage = (
      <CustomField key={`defaultImage-${contentField.id}`} label="Field default image">
        <InputImage
          value={isDefaultValueObject ? { src: defaultValue.src, text: defaultValue.text } : null}
          onChange={({ src, text }) => this.handleUpdate(`defaultValue.${language}`, { src, text })}
        />
      </CustomField>
    );

    const bttProductDefaultImage = (
      <CustomField key={`bttProductDefaultImage-${contentField.id}`} label="Product default image">
        <InputImage
          value={isDefaultValueObject ? { src: defaultValue.src, text: defaultValue.text } : null}
          onChange={({ src, text }) => this.handleUpdate(`defaultValue.${language}`, { src, text })}
        />
        {isDefaultValueObject && defaultValue.imageUrls && defaultValue.imageUrls.length > 1 ? (
          <div className="btt-product-image-option-list">
            {defaultValue.imageUrls.map((imageUrl) => (
              <div key={imageUrl} className="btt-product-image-option-item">
                <img
                  src={`${imageUrl}?h=100&fm=png`}
                  alt={imageUrl}
                  onClick={() => this.handleUpdate(`defaultValue.${language}.src`, imageUrl)}
                />
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}
      </CustomField>
    );

    const imageText = (
      <FormInput
        key={`image-text-${contentField.id}`}
        label={`Default image text - ${language}`}
        value={isDefaultValueObject ? defaultValue.text : ''}
        handleChange={(e) => this.handleUpdate(`defaultValue.${language}.text`, e.target.value)}
      />
    );

    const imageWithText = (
      <Checkbox
        key={`image-withText-${contentField.id}`}
        id={`image-withText-${contentField.id}`}
        label="Enable textfield for image"
        checked={this.getPropValue('withText')}
        handleClick={(e) => this.handleUpdate('props.withText', e.target.checked)}
      />
    );

    if (contentField.type === ContentFieldType.text) {
      return [minLength, maxLength, pattern, placeholder];
    }
    if ((contentField.type.toLowerCase() as ContentFieldType) === ContentFieldType.richtext) {
      return [minLength, maxLength, pattern, placeholder, allowedTags];
    }
    if (contentField.type === ContentFieldType.date) {
      return [minDate, maxDate];
    }
    if (contentField.type === ContentFieldType.select) {
      return options;
    }
    if (contentField.type === ContentFieldType.image) {
      return _.get(contentField, ['props', 'withText'], false) === true
        ? [defaultImage, imageWithText, imageText]
        : [defaultImage, imageWithText];
    }
    if (contentField.type === ContentFieldType.bttPromotedProduct) {
      return [bttProductDefaultImage];
    }
    return null;
  }

  getContent = (propertyName: string): string | Record<string, any> => {
    const { contentField } = this.props;
    const { language } = this.state;
    return _.get(contentField, `${propertyName}.${language}`, '');
  };

  render() {
    const { contentField, preventOverWrites, index, changeOrder, deleteField } = this.props;
    const { language } = this.state;
    const types: DropdownItem[] = Object.keys(ContentFieldType).map((key) => ({
      name: key,
      value: ContentFieldType[key],
    }));
    const selectedType: DropdownItem = types.find((t) => t.name === contentField.type);

    return (
      <section className="editor-section field-editor">
        <header className="editor-section__header">
          <div className="title-row">
            <h3 className="section-title">{this.getContent('title')}</h3>
            <div className="title-row__buttons">
              <LanguageSelector
                language={language}
                languages={['fi', 'sv']}
                toggleLanguage={(language: DeliveryLanguage) => this.setState({ language })}
              />
              <button onClick={() => changeOrder(index, false)}>
                <IconDown />
              </button>
              <button onClick={() => changeOrder(index, true)}>
                <IconUp />
              </button>
              <button className="delete-field delete-button" onClick={() => deleteField(contentField.id)}>
                <IconDelete />
              </button>
            </div>
          </div>
        </header>
        <div className="field-details">
          <span>Type: {ContentFieldType[contentField.type] || 'unknown'}</span>
          <span>HTML identifier: {contentField.identifier || 'unknown'}</span>
        </div>
        {!preventOverWrites && (
          <React.Fragment>
            <FormInput
              label="HTML identifier"
              value={contentField.identifier}
              handleChange={(e) => this.handleUpdate('identifier', e.target.value)}
              detail="The name used to refer to this field in HTML content. Note: identifier can not contain spaces."
              required={true}
            />
            <FormInput
              label={`Title - ${language}`}
              value={this.getContent('title') as string}
              handleChange={(e) => this.handleUpdate(`title.${language}`, e.target.value)}
              additionalClasses="half-wide"
            />
            <FormTextArea
              label={`Field description - ${language}`}
              value={this.getContent('description') as string}
              handleChange={(e) => this.handleUpdate(`description.${language}`, e.target.value)}
            />
            {(() => {
              switch (contentField.type) {
                case 'richtext': {
                  return (
                    <FormTextArea
                      label={`Default value - ${language}`}
                      value={this.getContent('defaultValue') as string}
                      handleChange={(e) => this.handleUpdate(`defaultValue.${language}`, e.target.value)}
                    />
                  );
                }
                case 'image':
                  return null;
                case 'select': {
                  const defaultSelectValue = this.getContent('defaultValue');
                  const values: DropdownItem[] = _.map(
                    _.compact(this.getPropValue('options').split(',')),
                    (option) =>
                      ({
                        name: option,
                        value: option,
                      }) as DropdownItem,
                  );

                  const selectedValue: DropdownItem = values.find((t) => t.value === defaultSelectValue);

                  return (
                    <CustomField additionalClasses="form-contro" label={`Default value - ${language}`}>
                      <Dropdown
                        data={values}
                        notSelectedText="Pick a default value"
                        selectedItem={selectedValue}
                        select={(value) => this.handleUpdate(`defaultValue.${language}`, value)}
                      />
                    </CustomField>
                  );
                }
                case 'date': {
                  const defaultDateValue = this.getContent('defaultValue') as string;
                  const tryFormatDateFromTo = (dateString: string, fromFormat: string, toFormat: string): string => {
                    const parsedDate = parse(dateString, fromFormat, new Date());
                    if (!isValid(parsedDate)) {
                      return '';
                    }
                    return format(parsedDate, toFormat);
                  };
                  return (
                    <FormInput
                      type="date"
                      label={`Default value - ${language}`}
                      value={tryFormatDateFromTo(defaultDateValue, 'd.M.yyyy', 'yyyy-MM-dd')}
                      handleChange={(e) => {
                        const value = e.target.value;
                        const newDefaultValue = tryFormatDateFromTo(value, 'yyyy-MM-dd', 'd.M.yyyy');
                        this.handleUpdate(`defaultValue.${language}`, newDefaultValue);
                      }}
                    />
                  );
                }
                case 'link': {
                  const defaultValue = this.getContent('defaultValue');
                  const isDefaultValueObject = typeof defaultValue === 'object';
                  return (
                    <React.Fragment>
                      <FormInput
                        label={`Default link text - ${language}`}
                        placeholder="Kesko"
                        value={isDefaultValueObject ? defaultValue.text : ''}
                        handleChange={(e) => this.handleUpdate(`defaultValue.${language}.text`, e.target.value)}
                      />
                      <FormInput
                        label={`Default link URL - ${language}`}
                        placeholder="https://kesko.fi/"
                        value={isDefaultValueObject ? defaultValue.href : ''}
                        handleChange={(e) => this.handleUpdate(`defaultValue.${language}.href`, e.target.value)}
                      />
                    </React.Fragment>
                  );
                }
                case 'bttPromotedProduct': {
                  const defaultValue = this.getContent('defaultValue');
                  const isDefaultValueObject = typeof defaultValue === 'object';
                  const isOnLoyaltySale = isDefaultValueObject ? defaultValue.isOnLoyaltySale : false;
                  return (
                    <React.Fragment>
                      <label>Search for product</label>
                      <BttProductSelect
                        closeSearchOnSelect={true}
                        onSelect={(product) => {
                          this.handleUpdate(`defaultValue.${language}`, {
                            ...product,
                            src: product.image,
                            imageUrls: product.imageUrls,
                            normalPriceVat0: undefined,
                            normalPriceVat24: product.basePrice,
                            salePriceVat24: product.isOnSale ? product.price : null,
                            productNumber: product.productId,
                          });
                        }}
                      />

                      <FormInput
                        label={`Product title - ${language}`}
                        placeholder="Product title"
                        value={isDefaultValueObject ? defaultValue.title : ''}
                        handleChange={(e) => this.handleUpdate(`defaultValue.${language}.title`, e.target.value)}
                        required={true}
                      />

                      <FormTextArea
                        label={`Product description - ${language}`}
                        rows={2}
                        placeholder="Product description"
                        value={isDefaultValueObject ? defaultValue.description : ''}
                        handleChange={(e) => this.handleUpdate(`defaultValue.${language}.description`, e.target.value)}
                      />

                      <InputNumber
                        name={`normalPriceVat24`}
                        label="Product normal price in € VAT 24%"
                        value={isDefaultValueObject ? defaultValue.normalPriceVat24 : ''}
                        onChange={(val) => {
                          this.handleUpdate(`defaultValue.${language}.normalPriceVat24`, val);
                        }}
                        allowEmptyFormatting
                        decimalScale={2}
                        min={0}
                        suffix={`€`}
                      />

                      <InputNumber
                        name={`normalPriceVat0`}
                        label="Product normal price in € VAT 0%"
                        value={isDefaultValueObject ? defaultValue.normalPriceVat0 : ''}
                        onChange={(val) => {
                          this.handleUpdate(`defaultValue.${language}.normalPriceVat0`, val);
                        }}
                        allowEmptyFormatting
                        decimalScale={2}
                        min={0}
                        suffix={`€`}
                      />

                      <InputNumber
                        name={`salePriceVat24`}
                        label="Product sale price in € VAT 24%"
                        value={isDefaultValueObject ? defaultValue.salePriceVat24 : ''}
                        onChange={(val) => {
                          this.handleUpdate(`defaultValue.${language}.salePriceVat24`, val);
                        }}
                        allowEmptyFormatting
                        decimalScale={2}
                        min={0}
                        suffix={`€`}
                      />

                      <FormInput
                        label={`Product pricing unit - ${language}`}
                        placeholder="Product pricing unit"
                        value={isDefaultValueObject ? defaultValue.pricingUnit : ''}
                        handleChange={(e) => this.handleUpdate(`defaultValue.${language}.pricingUnit`, e.target.value)}
                      />

                      <Checkbox
                        label="Is on Plussa sale?"
                        id={`product-isOnLoyaltySale-${language}`}
                        checked={isOnLoyaltySale}
                        handleClick={(e) =>
                          this.handleUpdate(`defaultValue.${language}.isOnLoyaltySale`, e.target.checked)
                        }
                      />

                      <InputNumber
                        name={`normalPriceVat24`}
                        label={`Product plussa sale price in € VAT 24% - ${language}`}
                        value={isDefaultValueObject ? defaultValue.loyaltyPrice : ''}
                        onChange={(val) => {
                          this.handleUpdate(`defaultValue.${language}.loyaltyPrice`, val);
                        }}
                        allowEmptyFormatting
                        decimalScale={2}
                        min={0}
                        suffix={`€`}
                        readOnly={!isOnLoyaltySale}
                      />

                      <FormInput
                        label={`Product EAN - ${language}`}
                        placeholder="6405422918614"
                        value={isDefaultValueObject ? defaultValue.ean : ''}
                        handleChange={(e) => this.handleUpdate(`defaultValue.${language}.ean`, e.target.value)}
                      />

                      <FormInput
                        label={`Product number - ${language}`}
                        placeholder="500987338"
                        value={isDefaultValueObject ? defaultValue.productNumber : ''}
                        handleChange={(e) =>
                          this.handleUpdate(`defaultValue.${language}.productNumber`, e.target.value)
                        }
                      />

                      <FormInput
                        label={`Link to product page - ${language}`}
                        placeholder="https://www.k-rauta.fi/tuote/akkuporakone-makita/88381754965"
                        value={isDefaultValueObject ? defaultValue.url : ''}
                        handleChange={(e) => this.handleUpdate(`defaultValue.${language}.url`, e.target.value)}
                      />
                    </React.Fragment>
                  );
                }
                case 'text':
                default: {
                  return (
                    <FormInput
                      label={`Default value - ${language}`}
                      value={this.getContent('defaultValue') as string}
                      handleChange={(e) => this.handleUpdate(`defaultValue.${language}`, e.target.value)}
                    />
                  );
                }
              }
            })()}
            {!preventOverWrites && (
              <CustomField additionalClasses="half-wide" label="Field type">
                <Dropdown
                  data={types}
                  notSelectedText="Pick a type"
                  selectedItem={selectedType}
                  select={this.onTypeChange}
                />
              </CustomField>
            )}
            <CustomField detail="Make field mandatory?" additionalClasses="half-wide">
              <Checkbox
                id={`field-${contentField.id}-required`}
                label="Required field"
                checked={contentField.required}
                handleClick={(e) => this.handleUpdate('required', e.target.checked)}
              />
            </CustomField>
            <CustomField detail="Allow user to change field value?" additionalClasses="half-wide">
              <Checkbox
                id={`field-${contentField.id}-readonly`}
                label="Read only"
                checked={contentField.readOnly}
                handleClick={(e) => this.handleUpdate('readOnly', e.target.checked)}
              />
            </CustomField>
            {this.renderPropEditor()}
          </React.Fragment>
        )}
      </section>
    );
  }
}
